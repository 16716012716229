import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useSearchParams } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import {
    Authenticator,
    CheckboxField,
    Image,
    Text,
    useAuthenticator,
    useTheme,
    View,
    Link,
    ThemeProvider
} from '@aws-amplify/ui-react';
import { Amplify } from "aws-amplify";
import { Hub } from 'aws-amplify/utils';

// Import the Amplify configuration
import { config } from './config/amplify-config';

// Import custom components
import { LanguageProvider, useLanguage } from './components/LanguageProvider';
import { LanguageSwitcher } from './components/LanguageSwitcher';
import { AuthenticatedHome } from './components/AuthenticatedHome';
import { ConfirmUserPage } from './components/ConfirmUserPage';
import { useAuthServices } from './hooks/useAuthServices';

// Configure Amplify
Amplify.configure(config);

// Authenticator components
const components = {
    Header() {
        const { tokens } = useTheme();

        return (
            <View textAlign="center" padding={tokens.space.large}>
                <Link
                    href="https://www.yolo-japan.com"
                    isExternal={false}
                    style={{
                        textDecoration: 'none',
                        display: 'inline-block'  // インライン要素をブロック化
                    }}
                >
                    <Image
                        alt="YOLO JAPAN logo"
                        src="https://www.yolo-japan.com/assets/images/base/logo_header.png?v202407251030"
                    />
                </Link>
            </View>
        );
    },

    Footer() {
        const { tokens } = useTheme();

        return (
            <View textAlign="center" padding={tokens.space.large}>
                <Text color={tokens.colors.neutral[80]}>
                    &copy; YOLO JAPAN CORPORATION.
                </Text>
            </View>
        );
    },

    SignUp: {
        FormFields() {
            const { validationErrors } = useAuthenticator();

            return (
                <>
                    <Authenticator.SignUp.FormFields />
                    <Link href="https://www.yolo-japan.com/ja/terms_of_service/" isExternal>
                        <FormattedMessage id="termsAndConditionsLink" />
                    </Link>
                    <Link href="https://www.yolo-japan.co.jp/privacy_policy/" isExternal>
                        <FormattedMessage id="privacyPolicyLink" />
                    </Link>
                    <CheckboxField
                        errorMessage={validationErrors.acknowledgement as string}
                        hasError={!!validationErrors.acknowledgement}
                        name="acknowledgement"
                        value="yes"
                        label={<FormattedMessage id="termsAndConditions" />}
                    />
                </>
            );
        },
    },
};

// Main App Content Component
const AppContent = () => {
    const { language } = useLanguage();
    const { handleSignUp, handleSignIn, validateCustomSignUp } = useAuthServices();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        // Check and update redirect_uri in localStorage
        const redirectUri = searchParams.get('redirect_uri');
        if (redirectUri) {
            localStorage.setItem('redirect_uri', redirectUri);
        }

        const unsubscribe = Hub.listen('auth', ({ payload }) => {
            switch (payload.event) {
                case 'signInWithRedirect':
                    console.log('Sign in successful');
                    break;
                case 'signInWithRedirect_failure':
                    console.error('Sign in failed:', payload.data);
                    if (payload.data instanceof Error) {
                        console.error('Error name:', payload.data.name);
                        console.error('Error message:', payload.data.message);
                        console.error('Error stack:', payload.data.stack);
                    }
                    break;
                case 'tokenRefresh':
                    console.log('Token refreshed successfully');
                    break;
                case 'tokenRefresh_failure':
                    console.error('Token refresh failed:', payload.data);
                    break;
            }
        });

        return () => {
            unsubscribe();
        };
    }, [searchParams]);

    // Authenticator services
    const services = {
        handleSignUp,
        handleSignIn,
        validateCustomSignUp,
    };

    return (
        <View>
            <LanguageSwitcher />
            <Authenticator
                key={language}
                loginMechanisms={['email']}
                socialProviders={['google']}
                components={components}
                services={services}
            >
                {() => (
                    <AuthenticatedHome />
                )}
            </Authenticator>
        </View>
    );
}

// Main App Component
const App = () => {
    return (
        <ThemeProvider>
            <LanguageProvider>
                <Authenticator.Provider>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/confirm-user" element={<ConfirmUserPage />} />
                            <Route path="/" element={<AppContent />} />
                        </Routes>
                    </BrowserRouter>
                </Authenticator.Provider>
            </LanguageProvider>
        </ThemeProvider>
    );
}

export default App;