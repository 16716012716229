import React, { createContext, useContext, useState, useEffect, useCallback, useRef } from 'react';
import { IntlProvider } from 'react-intl';
import { I18n } from 'aws-amplify/utils';
import { translations } from '@aws-amplify/ui-react';

// Import messages
import enMessages from '../locales/en.json';
import jaMessages from '../locales/ja.json';

const messages: { [key: string]: typeof enMessages } = {
    'en': enMessages,
    'ja': jaMessages,
};

// Language Context
type LanguageContextType = {
    language: string;
    setLanguage: (lang: string) => void;
};

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export const useLanguage = () => {
    const context = useContext(LanguageContext);
    if (context === undefined) {
        throw new Error('useLanguage must be used within a LanguageProvider');
    }
    return context;
};

interface LanguageProviderProps {
    children: React.ReactNode;
}

export const LanguageProvider: React.FC<LanguageProviderProps> = ({ children }) => {
    const [language, setLanguageState] = useState(() => {
        return localStorage.getItem('language') || navigator.language.split(/[-_]/)[0];
    });
    const isInitialMount = useRef(true);

    const setLanguage = useCallback((lang: string) => {
        setLanguageState(lang);
        localStorage.setItem('language', lang);
        I18n.setLanguage(lang);
    }, []);

    useEffect(() => {
        const setupI18n = async () => {
            I18n.putVocabularies(translations);
            I18n.putVocabularies({
                ja: jaMessages,
                en: enMessages
            });
            if (isInitialMount.current) {
                I18n.setLanguage(language);
                isInitialMount.current = false;
            }
        };

        setupI18n();
    }, [language]);

    return (
        <LanguageContext.Provider value={{ language, setLanguage }}>
            <IntlProvider
                messages={messages[language as keyof typeof messages] || messages['en']}
                locale={language}
                defaultLocale="en"
            >
                {children}
            </IntlProvider>
        </LanguageContext.Provider>
    );
};