export const config = {
    Auth: {
        Cognito: {
            userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID || '',
            userPoolClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID || '',
            passwordFormat: {
                minLength: 8,
                requireNumbers: true,
                requireLowercase: true,
                requireUppercase: true,
                requireSpecialCharacters: false,
            },
            userAttributes: {
                email: { required: true },
            },
            loginWith: {
                email: true,
                oauth: {
                    domain: process.env.REACT_APP_COGNITO_OAUTH_DOMAIN || '',
                    scopes: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
                    redirectSignIn: [process.env.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGN_IN || ''],
                    redirectSignOut: [process.env.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGN_OUT || ''],
                    responseType: 'code' as const,
                },
            },
            // signUpVerificationMethod: 'link' as const,
        },
    },
};