import { useCallback } from 'react';
import { useLanguage } from '../components/LanguageProvider';
import { signIn, signUp, fetchAuthSession, type SignInInput, type SignUpInput } from 'aws-amplify/auth';
import { I18n } from 'aws-amplify/utils';
import Cookies from 'js-cookie';
import { getCookeName, isSameDomain, getRootDomain } from '../utils/auth';

export const useAuthServices = () => {
    const { language } = useLanguage();

    const handlePostAuthSuccess = useCallback(async () => {
        const session = await fetchAuthSession();
        const idToken = session.tokens?.idToken?.toString();
        const accessToken = session.tokens?.accessToken?.toString();
        const sub = session.tokens?.idToken?.payload?.sub;
        if (!idToken || !accessToken || !sub) {
            throw new Error('Failed to get tokens');
        }

        const cookieOptions: Cookies.CookieAttributes = {
            secure: window.location.protocol === 'https:',
            sameSite: 'strict',
            domain: getRootDomain(window.location.hostname),
            expires: 7
        };

        Cookies.set(getCookeName(session, 'idToken'), idToken, cookieOptions);
        Cookies.set(getCookeName(session, 'accessToken'), accessToken, cookieOptions);
        Cookies.set(getCookeName(session, 'LastAuthUser'), sub, cookieOptions);

        const urlParams = new URLSearchParams(window.location.search);
        const redirectUri = urlParams.get('redirect_uri');
        if (redirectUri && isSameDomain(redirectUri)) {
            window.location.href = redirectUri;
        }
    }, []);

    const handleSignIn = useCallback(async (formData: SignInInput) => {
        try {
            const { username, password } = formData;
            if (username && password) {
                const result = await signIn({
                    username,
                    password,
                    options: {
                        authFlowType: 'USER_PASSWORD_AUTH'
                    }
                });
                if (result.isSignedIn) {
                    await handlePostAuthSuccess();
                }
                return result;
            } else {
                throw new Error('Username and password are required');
            }
        } catch (error) {
            console.error('Error during sign in:', error);
            throw error;
        }
    }, [handlePostAuthSuccess]);

    const handleSignUp = useCallback(async (formData: SignUpInput) => {
        const { username, password, options } = formData;

        const userAttributes = {
            ...(options?.userAttributes || {}),
            locale: language
        };

        try {
            const signUpResult = await signUp({
                username,
                password,
                options: {
                    ...options,
                    userAttributes,
                },
            });

            if (signUpResult.isSignUpComplete) {
                // Automatically sign in after successful sign up
                await handleSignIn({ username, password });
            }

            return signUpResult;
        } catch (error) {
            console.error('Error during sign up:', error);
            throw error;
        }
    }, [language, handleSignIn]);

    const validateCustomSignUp = useCallback(async (formData: { acknowledgement: any }) => {
        if (!formData.acknowledgement) {
            return {
                acknowledgement: I18n.get('termsAndConditionsError'),
            };
        }
        return undefined;
    }, []);

    return {
        handleSignUp,
        handleSignIn,
        validateCustomSignUp,
    };
};