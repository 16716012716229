import { AuthSession } from 'aws-amplify/auth';
import { config } from '../config/amplify-config';

export const getCookeName = (session: AuthSession, tokenType: string): string => {
    const authConfig = config.Auth.Cognito;
    const clientId = authConfig.userPoolClientId;

    if (tokenType === 'LastAuthUser') {
        return `CognitoIdentityServiceProvider.${clientId}.LastAuthUser`;
    }

    const sub = session.tokens?.idToken?.payload?.sub;

    return `CognitoIdentityServiceProvider.${clientId}.${sub}.${tokenType}`;
};

export const getRootDomain = (hostname: string): string => {
    if (hostname === 'localhost') {
        return hostname;
    }
    return hostname.split('.').slice(-2).join('.');
};

export const isSameDomain = (url: string): boolean => {
    const hostname = new URL(url).hostname;
    return getRootDomain(window.location.hostname) === getRootDomain(hostname);
};
