import { useCallback } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { signOut, fetchAuthSession } from 'aws-amplify/auth';
import Cookies from 'js-cookie';
import { getCookeName, getRootDomain } from '../utils/auth';

export const useCustomSignOut = () => {
    const { signOut: amplifySignOut } = useAuthenticator();

    const handleSignOut = useCallback(async () => {
        try {
            console.log('Custom Sign out');
            const session = await fetchAuthSession();

            // Cookieの共通オプション
            const cookieOptions: Cookies.CookieAttributes = {
                secure: window.location.protocol === 'https:',
                sameSite: 'strict',
                domain: getRootDomain(window.location.hostname),
            };

            // Cookieを削除
            Cookies.remove(getCookeName(session, 'idToken'), cookieOptions);
            Cookies.remove(getCookeName(session, 'accessToken'), cookieOptions);

            // Amplifyの標準サインアウト処理を呼び出す
            await signOut();
            amplifySignOut();
        } catch (error) {
            console.error('Error during sign out:', error);
            throw error;
        }
    }, [amplifySignOut]);

    return handleSignOut;
};