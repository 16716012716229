import React from 'react';
import { Button, View } from '@aws-amplify/ui-react';
import { useLanguage } from './LanguageProvider';

export const LanguageSwitcher: React.FC = () => {
    const { language, setLanguage } = useLanguage();

    return (
        <View textAlign="center">
            <Button
                onClick={() => setLanguage('en')}
                variation={language === 'en' ? 'primary' : 'link'}
            >
                English
            </Button>
            <Button
                onClick={() => setLanguage('ja')}
                variation={language === 'ja' ? 'primary' : 'link'}
            >
                日本語
            </Button>
        </View>
    );
};