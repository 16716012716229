import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Button,
    Card,
    Flex,
    Heading,
    Image,
    Text,
    View,
    Link,
    useTheme,
    Divider,
    Loader,
    useAuthenticator
} from '@aws-amplify/ui-react';
import { fetchUserAttributes, fetchAuthSession, AuthSession } from 'aws-amplify/auth';
import { useCustomSignOut } from '../hooks/useCustomSignOut';
import { isSameDomain, getCookeName, getRootDomain } from '../utils/auth';
import Cookies from 'js-cookie';

interface UserInfo {
    username: string;
    userId: string;
    email: string;
    sub: string;
    idTokenPayload: any;
}

export const AuthenticatedHome: React.FC = () => {
    const { user, authStatus } = useAuthenticator((context) => [context.user, context.authStatus]);
    const customSignOut = useCustomSignOut();
    const { tokens } = useTheme();
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
    const [loading, setLoading] = useState(true);
    const [redirectUri, setRedirectUri] = useState<string | null>(null);

    const renewCookies = async () => {
        try {
            const session = await fetchAuthSession();
            const idToken = session.tokens?.idToken?.toString();
            const accessToken = session.tokens?.accessToken?.toString();

            if (!idToken || !accessToken) {
                throw new Error('Failed to get tokens');
            }

            const cookieOptions: Cookies.CookieAttributes = {
                secure: window.location.protocol === 'https:',
                sameSite: 'strict',
                domain: getRootDomain(window.location.hostname),
                expires: 7
            };

            Cookies.set(getCookeName(session as AuthSession, 'idToken'), idToken, cookieOptions);
            Cookies.set(getCookeName(session as AuthSession, 'accessToken'), accessToken, cookieOptions);
            Cookies.set(getCookeName(session as AuthSession, 'LastAuthUser'), session.tokens?.idToken?.payload?.sub || '', cookieOptions);

            console.log('Cookies renewed successfully');
        } catch (error) {
            console.error('Error renewing cookies:', error);
            if (error instanceof Error) {
                console.error('Error name:', error.name);
                console.error('Error message:', error.message);
                console.error('Error stack:', error.stack);
            }
        }
    };

    useEffect(() => {
        const getUserInfo = async () => {
            if (authStatus === 'authenticated' && user) {
                try {
                    const session = await fetchAuthSession();
                    console.log('Current session:', session);

                    const attributes = await fetchUserAttributes();
                    console.log('User attributes:', attributes);
                    setUserInfo({
                        username: user.username || '',
                        userId: user.userId || '',
                        email: attributes.email || '',
                        sub: session.tokens?.idToken?.payload?.sub || '',
                        idTokenPayload: session.tokens?.idToken?.payload || {}
                    });
                    await renewCookies();
                } catch (error) {
                    console.error('Error fetching user info:', error);
                    if (error instanceof Error) {
                        console.error('Error name:', error.name);
                        console.error('Error message:', error.message);
                        console.error('Error stack:', error.stack);
                    }
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };

        getUserInfo();

        // Get redirect_uri from localStorage
        const storedRedirectUri = localStorage.getItem('redirect_uri');
        if (storedRedirectUri && isSameDomain(storedRedirectUri)) {
            setRedirectUri(storedRedirectUri);
        }
    }, [user, authStatus]);

    if (loading) {
        return (
            <View padding={tokens.space.large}>
                <Flex justifyContent="center" alignItems="center" height="100vh">
                    <Loader size="large" />
                </Flex>
            </View>
        );
    }

    const handleSignOut = async () => {
        try {
            await customSignOut();
            // Clear redirect_uri from localStorage on sign out
            localStorage.removeItem('redirect_uri');
        } catch (error) {
            console.error('Error during sign out:', error);
        }
    };

    const handleRedirect = () => {
        if (redirectUri) {
            window.location.href = redirectUri;
            // Clear redirect_uri from localStorage after redirect
            localStorage.removeItem('redirect_uri');
        }
    };

    return (
        <View
            padding={tokens.space.large}
            maxWidth="480px"
            margin="0 auto"
        >
            <Card>
                <Flex direction="column" alignItems="center">
                    <Link
                        href="https://www.yolo-japan.com"
                        isExternal={false}
                        style={{ textDecoration: 'none' }}
                    >
                        <Image
                            alt="YOLO JAPAN logo"
                            src="https://www.yolo-japan.com/assets/images/base/logo_header.png?v202407251030"
                            width="200px"
                            marginBottom={tokens.space.medium}
                        />
                    </Link>
                    <Heading level={3}>
                        <FormattedMessage id="welcomeMessage" defaultMessage="Welcome to YOLO JAPAN" />
                    </Heading>
                </Flex>

                <Divider marginTop={tokens.space.large} marginBottom={tokens.space.large} />

                {userInfo && (
                    <Flex direction="column" gap={tokens.space.medium}>
                        <Heading level={5}>
                            <FormattedMessage id="userInfoHeading" defaultMessage="User Information" />
                        </Heading>
                        <Text>
                            <FormattedMessage id="emailLabel" defaultMessage="Email" />: {userInfo.email}
                        </Text>
                        <Text
                            variation="tertiary"
                            fontSize="0.8em"
                        >
                            <FormattedMessage id="userIdLabel" defaultMessage="User ID" />: {userInfo.userId}
                        </Text>
                    </Flex>
                )}

                <Flex justifyContent="center" marginTop={tokens.space.xl} gap={tokens.space.medium}>
                    {redirectUri && (
                        <Button onClick={handleRedirect} variation="primary">
                            <FormattedMessage id="returnToAppButton" defaultMessage="Return to Application" />
                        </Button>
                    )}
                    <Button onClick={handleSignOut} variation="primary">
                        <FormattedMessage id="signOutButton" defaultMessage="Sign Out" />
                    </Button>
                </Flex>
            </Card>
        </View>
    );
};